import { iconPdf } from '@@images';
import { Block, Loading } from '@@components';
import React from 'react';
import { getGenericDocumentUrl } from '@@lib/contracts';
import PropTypes from 'prop-types';
import * as RA from 'ramda-adjunct';

const INTRO_TEXT = 'Effectuer une modification de mon/mes' +
  ' bénéficiaire(s) en cas de décès.';

const PensionPrimaryBlock = ({ loading, benefitsGuide, benefitsDocument }) => {
  const benefitsGuideUrl = getGenericDocumentUrl({
    document: benefitsGuide, download: false
  });

  const benefitsDocumentUrl = getGenericDocumentUrl({
    document: benefitsDocument, download: false
  });

  const benefitsDocumentButton = loading ? <Loading/>
    : RA.isNilOrEmpty(benefitsDocument) ? null : (
      <a className="f-button f-button-coral" href={ benefitsDocumentUrl }
        target="_blank" rel="noreferrer">
        {'Modifier la clause décès des bénéficiaires'}
      </a>
    );

  const benefitsGuideLink = loading ? <Loading/>
    : RA.isNilOrEmpty(benefitsGuide) ? null : (
      <div className="f-contract-pdf-link">
        <img src={iconPdf} alt="pdf-logo"/>
        <a className="f-contract-pdf-link-text" href={ benefitsGuideUrl }
          target="_blank" rel="noreferrer">
          {'En savoir plus sur la rédaction de ma clause bénéficiaires'}
        </a>
      </div>
    );

  return (
    <Block>
      <div className="f-contract-primary-block">
        <div className="f-contract-primary-title">
          {'Modifier ma clause décès'}
        </div>
        <div className="f-contract-text">
          {INTRO_TEXT}
        </div>
        <div className="f-contract-primary-buttons">
          {benefitsDocumentButton}
        </div>
        {benefitsGuideLink}
      </div>
    </Block>);
};

PensionPrimaryBlock.propTypes = {
  loading: PropTypes.bool,
  benefitsGuide: PropTypes.object,
  benefitsDocument: PropTypes.object
};

export default PensionPrimaryBlock;
