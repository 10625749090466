import React from 'react';
import propTypes from 'prop-types';
import { europAssistLogo } from '@@images';
import { Block } from '@@components';
import { useCms } from '@@hooks';
import * as R from 'ramda';
import { propOr } from 'ramda';
import { CONTRACT_TYPE_ASSISTANCE, CONTRACT_TYPE_HEALTH } from '@@lib/contracts';
import { configGA, sendGAEventIfDefined } from '@@lib/ga';

const AssistanceBlock = ({ contractType }) => {
  const { contact } = useCms(['contact']);
  const contactUsGa = R.cond([
    [R.equals(CONTRACT_TYPE_HEALTH), R.always(configGA.CONTRACT_HEALTH_CONTACT_ASSISTANCE)],
    [R.equals(CONTRACT_TYPE_ASSISTANCE), R.always(configGA.CONTRACT_ASSISTANCE_CONTACT_ASSISTANCE)]
  ])(contractType);
  const metroNumberGa = R.cond([
    [R.equals(CONTRACT_TYPE_HEALTH), R.always(configGA.CONTRACT_HEALTH_ASSISTANCE_METRO)],
    [R.equals(CONTRACT_TYPE_ASSISTANCE), R.always(configGA.CONTRACT_ASSISTANCE_ASSISTANCE_METRO)]
  ])(contractType);
  const dromNumberGa = R.cond([
    [R.equals(CONTRACT_TYPE_HEALTH), R.always(configGA.CONTRACT_HEALTH_ASSISTANCE_DROM)],
    [R.equals(CONTRACT_TYPE_ASSISTANCE), R.always(configGA.CONTRACT_ASSISTANCE_ASSISTANCE_DROM)]
  ])(contractType);

  const handleOnClick = ga => () => {
    sendGAEventIfDefined(ga);
  };

  return (
    <Block>
      <div className={'f-contract-secondary-block'}>
        <div className={'f-contract-secondary-title'}>
          {'Assistance APPA'}
        </div>
        <div className={'f-contract-secondary-img'}>
          <img src={europAssistLogo} alt={'logo'}/>
        </div>
        <div className={'f-contract-secondary-text'}>
          <p>{'Besoin d\'aide ?'}</p>
          <p>{'Contactez EUROP ASSISTANCE'}</p>
        </div>
        <div>
          <div
            className={'f-contact-block-phone-number'}
            onClick={handleOnClick(metroNumberGa)}
          >
            {propOr('', 'europAssistanceMetropolePhoneNumber', contact)}
          </div>
          <div className={'f-contact-block-caption'}>
            {'(en France métropolitaine)'}
          </div>
        </div>

        <div>
          <div
            className={'f-contact-block-phone-number'}
            onClick={handleOnClick(dromNumberGa)}
          >
            {propOr('', 'europAssistanceDROMPhoneNumber', contact)}
          </div>
          <div className={'f-contact-block-caption'}>
            {'(pour les DROM)'}
          </div>
        </div>

        <div className={'f-contract-secondary-buttons'}>
          <a className={'f-button f-button-coral'}
            href={propOr('', 'europAssistanceURL', contact)}
            target="_blank" rel="noreferrer" onClick={handleOnClick(contactUsGa)}
          >
            {'Nous contacter'}
          </a>
        </div>
      </div>
    </Block>
  );
};

AssistanceBlock.propTypes = {
  contractType: propTypes.string
};

export default AssistanceBlock;
