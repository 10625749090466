import React from 'react';
import PropTypes from 'prop-types';
import {
  CONTRACT_TYPE_ASSISTANCE,
  CONTRACT_TYPE_HEALTH,
  CONTRACT_TYPE_PENSIONH,
  isContractOfType
} from '@@lib/contracts';
import * as R from 'ramda';
import { Link } from 'react-router-dom';
import { configGA, sendGAEventIfDefined } from '@@lib/ga';

const ContractDetailsHeader = ({ contract }) => {
  const ga = R.cond([
    [isContractOfType(CONTRACT_TYPE_HEALTH), R.always(configGA.CONTRACT_HEALTH_UPDATE_CONTRACT)],
    [isContractOfType(CONTRACT_TYPE_PENSIONH), R.always(configGA.CONTRACT_PENSION_HOSPI_UPDATE_CONTRACT)]
  ])(contract);
  return R.not(isContractOfType(CONTRACT_TYPE_ASSISTANCE)(contract)) && (
    <div className={'f-contract-details-head-btn'}>
      <Link className={'f-button f-button-coral'}
        onClick={() => sendGAEventIfDefined(ga)}
        to={'/messagerie/ouvrir?action=03'}
      >
        {'Modification de contrat'}
      </Link>
    </div>
  );
};

ContractDetailsHeader.propTypes = {
  contract: PropTypes.object
};

export default ContractDetailsHeader;
