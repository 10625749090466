import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import QAllDiscussions from '@@queries/QAllDiscussions';
import { useReferences } from '@@hooks';
import { DiscussionFrame, DiscussionList, NewDiscussionFrame } from './components';
import { StringParam, useQueryParam } from 'use-query-params';
import classNames from 'classnames';
import { discussionStatus } from '@@enum';
import HasBeenNotifiedMutation from '../../_graphql/mutations/HasBeenNotifiedMutation';
import { useRelayEnvironment } from 'react-relay';
import useMessageNotifier from '@@hooks/useMessageNotifier';
import { getUnreadDiscussionIds } from '@@pages/message/utils';

const R = require('ramda');
const RA = require('ramda-adjunct');

const Home = ({ match, pathConfig, history }) => {
  const environment = useRelayEnvironment();
  const {
    extranetMessageThemes,
    backOfficeMessageThemes
  } = useReferences(['backOfficeMessageThemes', 'extranetMessageThemes']);
  const { messageNotified } = useMessageNotifier();
  const unreadDiscussions = getUnreadDiscussionIds(messageNotified);

  const discussionId = R.path(['params', 'discussionId'], match);
  const doDisplayNewDiscussion = R.equals(discussionId, 'ouvrir');
  const doDisplayDiscussion = RA.isNotNil(discussionId);

  useEffect(() => {
    if (!doDisplayNewDiscussion && doDisplayDiscussion) {
      // console.log('===> should update - has been read ', discussionId);
      HasBeenNotifiedMutation(environment, { discussionId }, () => {});
    }
  }, [discussionId]);

  const [action, setAction] = useQueryParam('action', StringParam);
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState(discussionStatus.OPENED);
  const [messageInfo, setMessageInfo] = React.useState({ themeId: null, subThemeId: null, attachments: null });
  const [openInputs, setOpenInputs] = React.useState({ subTheme: false, reply: false });
  const [subThemeValue, setSubThemeValue] = React.useState('');

  const onChangeAction = (action) => {
    setAction(action);
  };

  const onSelectDiscussion = (discussionId) => {
    history.push(pathConfig.viewPath(discussionId));
  };

  const onChangeSubTheme = (subTheme) => {
    onChangeAction(subTheme.value);
    setSubThemeValue(subTheme);
  };

  const handleNewMessageButton = () => {
    onChangeSubTheme('');
    setMessageInfo({ themeId: null, subThemeId: null, attachments: null });
    setOpenInputs({ subTheme: false, reply: false });
  };

  const discussionTitle = (actionId) => {
    const actionIdGroups = R.match(/(?:BO)?ACTION-(\d{2})-?(\d{2})?/, actionId);
    const [themeId, subThemeId] = R.drop(1, actionIdGroups);
    const getMessageThemes = R.includes('BO', actionId) ? backOfficeMessageThemes : extranetMessageThemes;
    let themeTitle = '';
    if (!R.isNil(getMessageThemes)) {
      themeTitle = R.propOr('', 'title', R.find(R.propEq('id', themeId), getMessageThemes));
    }
    const subThemeTitle = R.ifElse(
      RA.isNotNilOrEmpty,
      R.compose(
        R.propOr('', 'title'),
        R.find(R.propEq('id', `${themeId}-${subThemeId}`)),
        R.propOr([], 'subThemes'),
        R.find(R.propEq('id', themeId))
      ),
      R.always([])
    );
    return R.join(' - ', R.reject(R.anyPass([R.isNil, R.isEmpty]), [themeTitle, subThemeTitle(getMessageThemes)]));
  };

  const isStatusOpened = R.equals(status, discussionStatus.OPENED);
  const onTabClick = status => () => {
    setStatus(status);
    setPage(1);
  };
  const leftMenuHeader = (
    <div className={'f-container-messaging-left-header-wrapper'}>
      <div className="f-container-messaging-left-header">
        <div className="f-container-messaging-left-title">
          {'Mes messages'}
        </div>
        <Link className="f-button f-button-coral f-responsive-btn"
          onClick={handleNewMessageButton}
          to={pathConfig.newMessagePath}>
          {'Nouveau message'}
        </Link>
      </div>
      <div className="f-container-messaging-left-tabs">
        <button
          onClick={onTabClick(discussionStatus.OPENED)}
          className={`${isStatusOpened ? 'f-button-discussions-status-underlined' : 'f-button-discussions-status'}`}
        >
          {'En cours'}
        </button>
        <button
          onClick={onTabClick(discussionStatus.CLOSED)}
          className={`${isStatusOpened ? 'f-button-discussions-status' : 'f-button-discussions-status-underlined'}`}
        >
          {'Clôturés'}
        </button>
      </div>
    </div>
  );

  // In desktop view, the title leads the page.
  // In mobile view, the title leads the leftPart.
  const pageTitle = <div className={'f-page-main-title'}>{'Messagerie'}</div>;

  const showRightPanel = R.or(doDisplayNewDiscussion, doDisplayDiscussion);

  const getRightPart = () => {
    if (doDisplayNewDiscussion) {
      return (
        <NewDiscussionFrame
          action={action}
          onChangeAction={onChangeAction}
          messageInfo={messageInfo}
          setMessageInfo={setMessageInfo}
          openInputs={openInputs}
          setOpenInputs={setOpenInputs}
          onChangeSubTheme={onChangeSubTheme}
          setSubThemeValue={setSubThemeValue}
          subThemeValue={subThemeValue}
        />
      );
    }
    if (doDisplayDiscussion) {
      return (
        <DiscussionFrame
          discussionId={discussionId}
          discussionTitle={discussionTitle}
        />
      );
    }
    return null;
  };

  const rightPart = (
    <div className={classNames(
      'f-container-messaging-right',
      { 'f-mobile-hidden': !showRightPanel })}
    >
      {getRightPart()}
    </div>
  );

  const leftPart = (
    <div className={classNames(
      'f-container-messaging-left',
      { 'f-mobile-hidden': showRightPanel })}
    >
      <div className={'f-mailbox-secondary-title'}>
        {pageTitle}{/* Page Title - only visible on mobile */}
      </div>
      <div className={'f-container-messaging-left-body'}>
        {leftMenuHeader}
        <QAllDiscussions args={{ filter: { status: [status] }, paginate: { page, limit: 10 } }}>
          {({ allDiscussions }) => (
            <DiscussionList
              current={discussionId}
              items={allDiscussions.data}
              pagination={allDiscussions.pagination}
              onSelect={onSelectDiscussion}
              discussionTitle={discussionTitle}
              unreadDiscussions={unreadDiscussions}
              setPage={setPage}
            />
          )}
        </QAllDiscussions>
      </div>
    </div>
  );

  // In mobile view, either leftPart or rightPart is visible at any point
  if (!extranetMessageThemes || !backOfficeMessageThemes) {
    return null;
  }
  return (
    <div className={'f-mailbox-page-container f-page-with-banner-bg'}>
      <div className={'f-page'}>
        <div className={'f-page-body'}>
          <div className={'f-mailbox-main-title'}>
            {pageTitle}{/* Page Title - only visible on desktop */}
          </div>
          <div className={'f-block-page-messaging'}>
            {leftPart}
            {rightPart}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
