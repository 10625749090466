import React, { useState } from 'react';
import { useReferences, useAdherent, useKeycloakSession } from '@@hooks';
import ExpectedAttachments from './ExpectedAttachments';
import { SelectOneOption } from '@@components/input';
import useForms from '@@formsHook';
import Forms from '@@components/forms';
import S from 'string';
import { MessageRecord } from '@@graphql/records/MessagesRecord';
import { ErrorMessage } from '@@components';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { BreadCrumbButtons } from '@@components/BreadCrumb';
import RequestConfirmModal from './RequestConfirmModal';
import ExternalServicesModal, { ExternalServices } from './ExternalServicesModal';
import { ATTACHMENT_ID_OTHER } from '@@pages/message/components/utils';
import { filterActiveContracts } from '@@lib/contracts';

const NewDiscussionFrame = ({
  action,
  onChangeAction,
  setMessageInfo,
  messageInfo,
  setOpenInputs,
  openInputs,
  onChangeSubTheme,
  setSubThemeValue,
  subThemeValue
}) => {
  const { user } = useKeycloakSession();
  const {
    adherent
  } = useAdherent(user.customerId, ['contracts']);
  const {
    extranetMessageThemes,
    messageAttachmentTypes
  } = useReferences(['extranetMessageThemes', 'messageAttachmentTypes']);
  const [attachedMessageTypes, setAttachedMessageTypes] = React.useState([]);
  const [data, setData] = React.useState({
    text: '',
    attachments: []
  });
  const [errorMessage, setErrorMessage] = React.useState(null);
  // dictates whether or not the modal is visible
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  // dictates the type of displayed modal (true===success, false===error)
  const [successConfirm, setSuccessConfirm] = useState(false);
  const [showExternalServices, setShowExternalServices] = useState(false);
  const [externalService, setExternalService] = useState('');
  const {
    fetching,
    onFormsSubmit,
    formsData
  } = useForms('sendDiscussion', {
    updater: (input, store, response) => {
      const hasError = RA.isNotNil(R.path(['sendDiscussion', 'error'], response));
      const isResponseNil = R.isNil(R.prop('sendDiscussion', response));
      if (hasError || isResponseNil) {
        return;
      }
      const discussionId = R.path(['sendDiscussion', 'discussionId'], response);

      const message = {
        ...R.path(['sendDiscussion', 'message'], response),
        dates: R.path(['sendDiscussion', 'dates'], response),
        attachments: R.path(['sendDiscussion', 'attachments'], response)
      };

      const rec = MessageRecord(store);
      rec.createDiscussion(discussionId, message);
    }
  });

  const themeIdMercer = '08';
  const themeIdEuropAssistance = '09';
  const $onChangeThemeId = (themeId) => (e) => {
    if (R.equals(themeIdMercer, themeId)) {
      openExternalServicesModal(ExternalServices.MERCER);
    } else if (R.equals(themeIdEuropAssistance, themeId)) {
      openExternalServicesModal(ExternalServices.EUROP_ASSISTANCE);
    } else {
      e.preventDefault();
      onChangeAction(themeId);
    }
  };

  const attachmentsRef = React.useRef(null);
  const onFormChange = (v) => {
    setData(v);
    if (R.compose(R.is(Array), R.prop('attachments'))(v)) {
      if (!R.equals(attachmentsRef.current, v.attachments)) {
        attachmentsRef.current = v.attachments;
        setAttachedMessageTypes(R.map(R.prop('documentType'))(v.attachments));
      } else if (!R.isNil(attachmentsRef.current)) {
        attachmentsRef.current = null;
        setAttachedMessageTypes([]);
      }
    }
  };

  const onFormSubmit = (v) => {
    onFormsSubmit(v);
  };

  const onCancel = () => {
    onChangeAction();
    setMessageInfo({ themeId: null, subThemeId: null, attachments: null });
  };

  // Deploy the modal with a success or an error
  const openConfirmModal = (success) => {
    setShowConfirmModal(true);
    setSuccessConfirm(success);
  };

  // Close the modal. If it was a success, leave the current message
  const closeConfirmModal = () => {
    setShowConfirmModal(false);
    if (successConfirm) {
      onChangeAction('');
      setMessageInfo({ themeId: null, subThemeId: null, attachments: null });
      setAttachedMessageTypes([]);
      setErrorMessage(null);
    }
  };

  const openExternalServicesModal = service => {
    setExternalService(service);
    setShowExternalServices(true);
  };
  const closeExternalServicesModal = () => {
    setShowExternalServices(false);
  };

  const errorBox = RA.isNotNilOrEmpty(errorMessage) && (
    <ErrorMessage value={errorMessage}/>
  );

  React.useEffect(() => {
    setErrorMessage(fetching.getError());
    if (fetching.getError()) {
      openConfirmModal(false);
    }
    if (fetching.isDone()) {
      fetching.reset();
      openConfirmModal(true);
    }
  }, [fetching.isDone(), fetching.getError()]);

  React.useEffect(() => {
    if (R.isNil(extranetMessageThemes)) return;
    if (R.isNil(action) || R.isEmpty(action)) {
      setMessageInfo({ themeId: null, subThemeId: null });
      setOpenInputs({ subTheme: false, reply: false });
    } else if (!S(action).contains('-')) {
      const theme = R.find(R.propEq('id', action), extranetMessageThemes);
      if (R.isNil(theme.subThemes)) {
        setOpenInputs({ subTheme: false, reply: true });
      } else {
        setOpenInputs({ subTheme: true, reply: false });
      }
      setMessageInfo({ themeId: action, subThemeId: null });
    } else {
      const info = action.split('-');
      setMessageInfo({ themeId: info[0], subThemeId: info[1] });
      const theme = R.find(R.propEq('id', info[0]), extranetMessageThemes);
      const options = R.map(R.applySpec({
        label: R.prop('title'),
        value: R.prop('id'),
        attachments: R.prop('attachments')
      }))(theme.subThemes);
      setSubThemeValue(R.find(R.propEq('value', action), options));
      setOpenInputs({ subTheme: true, reply: true });
    }
  }, [action, extranetMessageThemes]);

  if (R.isNil(extranetMessageThemes) || R.isNil(messageAttachmentTypes)) return null;

  if (fetching.isDone()) {
    return (
      <div>
        <h3>Votre message a été envoyé</h3>
        <button
          className="f-button f-button-coral mr-4"
          onClick={onCancel}
        >
          {'Envoyer une nouvelle demande'}
        </button>
      </div>
    );
  }

  if (R.isNil(messageInfo.themeId)) {
    const externalServicesModal = <ExternalServicesModal
      show={showExternalServices}
      setDisabled={closeExternalServicesModal}
      service={externalService}
    />;
    return (
      <>
        {externalServicesModal}
        <div className={'f-mailbox-breadcrumb'}>
          <BreadCrumbButtons elements={[
            { name: 'Messagerie', link: '/messagerie' },
            { name: 'Nouveau Message' }
          ]}/>
        </div>
        <div className={'f-container-blocks-discussion-select-title'}>
          {'Quel est le sujet de votre demande ?'}
        </div>
        <div className={'f-container-blocks-discussion-select'}>
          {extranetMessageThemes.map((theme) => (
            <div className={'f-block-discussion-select'}
              onClick={$onChangeThemeId(theme.id)}
              key={theme.id}>
              <div className={'f-block-discussion-select-title'}>
                {theme.title}
              </div>
              <div className={'f-block-discussion-select-text'}>
                {R.propOr('', 'label', theme)}
              </div>
            </div>
          ))}
        </div>
      </>
    );
  }

  const theme = R.find(R.propEq('id', messageInfo.themeId), extranetMessageThemes);

  const options = R.map(R.applySpec({
    label: R.prop('title'),
    value: R.prop('id'),
    attachments: R.prop('attachments')
  }))(R.propOr([], 'subThemes', theme));

  const contractOptions = R.compose(
    R.map(R.applySpec({
      label: R.path(['detail', 'label']),
      value: {
        contractId: R.path(['detail', 'contract']),
        categoryId: R.path(['detail', 'category'])
      }
    })),
    filterActiveContracts,
    R.propOr([], 'contracts')
  )(adherent);

  const mandatoryAttachment = R.compose(
    R.prop('attachments'),
    R.find(R.propEq('value', `${messageInfo.themeId}-${messageInfo.subThemeId}`))
  )(options);
  const idInMandatoryAttachments = id => R.compose(
    R.any(R.includes(id)),
    R.propOr([], 'mandatory')
  )(mandatoryAttachment);
  const idInOptionalAttachments = id => R.compose(
    R.includes(id),
    R.chain(R.propOr([], 'attachement')),
    R.propOr([], 'optional')
  )(mandatoryAttachment);
  const idOther = R.equals(ATTACHMENT_ID_OTHER);
  const idInAcceptedAttachments = R.propSatisfies(R.anyPass([
    idInMandatoryAttachments,
    idInOptionalAttachments,
    idOther
  ]), 'id');
  const attachmentsOptions = R.compose(
    R.filter(idInAcceptedAttachments),
    R.defaultTo([])
  )(messageAttachmentTypes);

  const disabledBtn = (attachments) => {
    // On vérifie la si le sous theme attend des pièces jointes
    if (mandatoryAttachment) {
      const mapDocumentTypesFromUserAttachments = R.map(R.prop('documentType'))(attachments);

      // On compare les pièces jointes de l'adhérent avec ce qui est attendu par le sous thème
      const referentialContainsAttachment = attachment => R.any(R.includes(R.__, attachment));

      // On récupère les pièces manquantes
      const getMissedAttachment = attachment => R.reject(referentialContainsAttachment(attachment));

      // On récupère les pièces jointes manquantes et on les stocks dans cette constante
      const missedAttachments = getMissedAttachment(mapDocumentTypesFromUserAttachments)(mandatoryAttachment.mandatory);

      // Si le tableau 'missedAttachments' n'est pas vide, alors on désactive le bouton
      return RA.isNotEmpty(missedAttachments) || RA.isNilOrEmpty(data.text) || fetching.isFetching();
    }
    return RA.isNilOrEmpty(data.text) || fetching.isFetching();
  };

  if (!R.isNil(messageInfo.themeId)) {
    const theme = S(action).contains('-')
      ? R.find(R.propEq('id', R.split('-', action)[0]), extranetMessageThemes)
      : R.find(R.propEq('id', action), extranetMessageThemes);

    const requestConfirmationModal = <RequestConfirmModal
      show={showConfirmModal}
      setDisabled={closeConfirmModal}
      success={successConfirm}
    />;

    return (
      <>
        {requestConfirmationModal}
        <div className="f-mailbox-breadcrumb">
          <BreadCrumbButtons elements={[
            { name: 'Messagerie', link: '/messagerie' },
            // TODO this redirect link crashes the client
            { name: 'Nouveau Message', link: '/messagerie/ouvrir' },
            { name: theme.title }
          ]}/>
        </div>
        <div className="f-container-blocks-discussion-select-title">
          {theme.title}
        </div>
        <div className="f-container-discussion-forms">
          {
            openInputs.subTheme && (
              <div className="f-block-field mb-2">
                <div className="f-block-field-row-header-label mb-2">
                  {'Quelles informations souhaitez-vous mettre à jour ?'}
                </div>
                <SelectOneOption
                  /* value={state.messageSubTheme} */
                  onChange={onChangeSubTheme}
                  placeholder="Vous souhaitez modifier..."
                  options={options}
                  value={subThemeValue}
                />
              </div>
            )}
          {
            openInputs.reply && (
              <Forms
                formsData={formsData}
                defaultValues={{
                  actionId: messageInfo.subThemeId ? `ACTION-${messageInfo.themeId}-${messageInfo.subThemeId}` : `ACTION-${messageInfo.themeId}`
                }}
                columns={1}
                onChange={onFormChange}
                onSubmit={onFormSubmit}
                options={{
                  documentTypeOptions: attachmentsOptions,
                  contractOptions
                }}
                submitSection={() => (
                  <>
                    <ExpectedAttachments
                      types={messageAttachmentTypes}
                      attachedTypes={attachedMessageTypes}
                      value={mandatoryAttachment}
                    />
                    { errorBox }
                    <div className="f-discussion-forms-buttons">
                      <button
                        className="f-button f-button-grey"
                        disabled={fetching.isFetching()}
                        onClick={onCancel}
                      >
                        {'Annuler'}
                      </button>
                      <button
                        className="f-button f-button-coral"
                        disabled={disabledBtn(R.propOr([], 'attachments', data))}
                        type="submit"
                      >
                        {'Envoyer'}
                      </button>
                    </div>
                  </>
                )}
              />
            )
          }
        </div>
      </>
    );
  }
};

export default NewDiscussionFrame;
