import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Block } from '@@components';
import React from 'react';
import { configGA, sendGAEventIfDefined } from '@@lib/ga';

const HealthPrimaryBlock = ({ mercerUrl }) => {
  const onClickConsultBeneficiaries = () => sendGAEventIfDefined(configGA.CONTRACT_HEALTH_BENEFICIARIES);
  const onClickUpdateBeneficiaries = () => sendGAEventIfDefined(configGA.CONTRACT_HEALTH_UPDATE_BENEFICIARIES);
  return (
    <Block>
      <div className="f-contract-primary-block">
        <div className="f-contract-primary-title">
          {'Mes bénéficiaires santé'}
        </div>
        <div className="f-contract-text">
          <p>
            {'Le détail des bénéficiaires de votre contrat santé est' +
              ' disponible sur votre espace assuré Mercer.'}
          </p>
          <p>
            {'Pour le consulter, cliquez sur « Consulter mes bénéficiaires' +
              ' », une nouvelle fenêtre s’ouvrira.'}
          </p>
        </div>
        <div className="f-contract-primary-buttons">
          <a
            className="f-button f-button-coral" href={mercerUrl}
            target="_blank" rel="noreferrer"
            onClick={onClickConsultBeneficiaries}
          >
            {'Consulter mes bénéficiaires'}
          </a>
          <Link className={'f-button-coral-reverse-rounded'}
            to={'/messagerie/ouvrir?action=03'}
            onClick={onClickUpdateBeneficiaries}
          >
            {'Modifier mes bénéficiaires'}
          </Link>
        </div>
      </div>
    </Block>
  );
};

HealthPrimaryBlock.propTypes = {
  mercerUrl: PropTypes.string.isRequired
};

export default HealthPrimaryBlock;
