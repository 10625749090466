import { staffofAsclepiusLogo } from '@@images';
import { Block } from '@@components';
import React from 'react';
import PropTypes from 'prop-types';
import { configGA, sendGAEventIfDefined } from '@@lib/ga';

const INTRO_TEXT = 'Consulter mes remboursements santé, accéder à mon' +
  ' espace Santéclair, demander une prise en charge hospitalière ou' +
  ' consulter mes bénéficiaires.';

const HealthSecondaryBlock = ({ mercerUrl }) => {
  const onClickConsultRefunds = () => sendGAEventIfDefined(configGA.CONTRACT_HEALTH_REFUNDS);
  return (
    <Block>
      <div className="f-contract-secondary-block">
        <div className="f-contract-secondary-title">
          {'Mes derniers remboursements'}
        </div>
        <div className="f-contract-secondary-img">
          <img src={staffofAsclepiusLogo} alt="staff logo"/>
        </div>
        <div className="f-contract-text">
          {INTRO_TEXT}
        </div>
        <div className="f-contract-secondary-buttons">
          <a className="f-button f-button-coral" href={mercerUrl}
            target="_blank" rel="noreferrer"
            onClick={onClickConsultRefunds}
          >
            {'Consulter'}
          </a>
        </div>
        {/* <div className="f-contract-pdf-link">
          <div className="f-contract-pdf-link-text">
            {'Comment naviguer dans mon espace santé Mercer'}
          </div>
        </div> */}
      </div>
    </Block>
  );
};

HealthSecondaryBlock.propTypes = {
  mercerUrl: PropTypes.string.isRequired
};

export default HealthSecondaryBlock;
