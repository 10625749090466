import React from 'react';
import Modal from '@@components/modale';
import { documentLogo } from '@@images';
import PropTypes from 'prop-types';
import { QCmsStaticDocumentsQuery } from '@@queries/QCmsStaticDocuments';
import { useFetchQuery } from '@@hooks/useDocument';
import { STATIC_DOC_TYPE_TUTORIEL_ONBOARDING } from '@@pages/documents/utils';
import { getGenericDocumentUrl } from '@@lib/contracts';
import * as R from 'ramda';
import { Loading } from '@@components';

const TutoFirstConnectionModal = ({ show, onDisabled }) => {
  const {
    isLoaded: isLoadedListDocuments,
    data: dataListDocuments
  } = useFetchQuery(QCmsStaticDocumentsQuery,
    { documentsFilter: { type: STATIC_DOC_TYPE_TUTORIEL_ONBOARDING } });

  const [onboardingTutoUrl, setOnboardingTutoUrl] = React.useState('');

  React.useEffect(() => {
    if (isLoadedListDocuments) {
      const doc = R.compose(
        R.head,
        R.pathOr('', ['cms', 'staticDocuments'])
      )(dataListDocuments);
      const url = getGenericDocumentUrl({ document: doc, download: false });
      setOnboardingTutoUrl(url);
    }
  }, [isLoadedListDocuments, dataListDocuments]);

  return (
    <Modal show={show} onDisable={onDisabled}
      className={'f-window-messaging-confirm'}
    >
      <div className={'f-messaging-confirm-modal-container'}>
        <img className='f-messaging-modal-img' src={documentLogo} alt="img"/>
        <div className={'f-messaging-modal-text'}>
          <p>
            {'Besoin d\'aide pour créer votre compte ?'}
          </p>
          <p>
            {'Consultez notre tutoriel'}
          </p>
        </div>
        <div className={'f-messaging-modal-buttons'}>
          {
            isLoadedListDocuments ? <a
              className={'f-button f-button-coral f-edit-family-status-btn'}
              href={onboardingTutoUrl}
              target="_blank"
              rel="noreferrer"
            >
              {'Consulter'}
            </a> : <Loading />
          }
        </div>
        <button
          type="button"
          className={'text-popup-text text-turquoise font-bold mt-4'}
          to="/account/signin/signin"
          onClick={onDisabled}
        >
          {'Fermer le Tutoriel'}
        </button>
      </div>
    </Modal>
  );
};

TutoFirstConnectionModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onDisabled: PropTypes.func.isRequired
};

export default TutoFirstConnectionModal;
