import ReactGA from 'react-ga';
import { isNotNilOrEmpty } from 'ramda-adjunct';
import { fetchQuery } from 'react-relay';
import { QGAnalyticsConfigQuery } from '@@queries/QGAnalyticsConfig';
import { has } from 'ramda';

export const initGAnalytics = async (environment) => {
  const { configGa } = await fetchQuery(environment, QGAnalyticsConfigQuery).toPromise();
  if (has('key', configGa)) {
    ReactGA.initialize(configGa.key);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
};

export const sendGAEventIfDefined = ga => {
  if (isNotNilOrEmpty(ga)) {
    ReactGA.event(ga);
  }
};

export const configGA = {
  HEADER_MESSAGING: {
    category: 'Messagerie',
    action: 'Clic - CTA',
    label: 'Pré header - messagerie'
  },
  HEADER_PROFILE: {
    category: 'Profil',
    action: 'Clic - CTA',
    label: 'Pré header - profil'
  },
  HEADER_CALL_US: {
    category: 'Contact gestion',
    action: 'Clic - CTA',
    label: 'Pré header - contact gestion'
  },
  HEADER_LOGOUT: {
    category: 'Déconnexion',
    action: 'Clic - CTA',
    label: 'Pré header - deconnexion'
  },
  MENU_CONTRACTS: {
    category: 'Contrats',
    action: 'Lien',
    label: 'Header - contrats'
  },
  MENU_DOCUMENTS: {
    category: 'Documents',
    action: 'Lien',
    label: 'Header - documents'
  },
  MENU_CONTRIBUTIONS: {
    category: 'Cotisations',
    action: 'Lien',
    label: 'Header - Cotisations'
  },
  HOME_REFUNDS: {
    category: 'MERCER',
    action: 'Clic - CTA',
    label: 'Accueil - remboursement santé'
  },
  HOME_LAST_DOCUMENT: {
    category: 'Dernier document',
    action: 'Clic - CTA',
    label: 'Accueil - dernier document'
  },
  HOME_ACCESS_MESSAGING: {
    category: 'Messagerie',
    action: 'Lien',
    label: 'Accueil - messagerie'
  },
  HOME_ACCESS_LAST_MESSAGE: {
    category: 'Réponse messagerie',
    action: 'Clic - CTA',
    label: 'Accueil - réponse messagerie'
  },
  HOME_DOCUMENTS: {
    category: 'Document',
    action: 'Lien',
    label: 'Accueil - document'
  },
  HOME_SERVICE_MERCER: {
    category: 'MERCER',
    action: 'Clic - CTA',
    label: 'Accueil - espace santé'
  },
  HOME_SERVICE_ASSISTANCE: {
    category: 'Assistance',
    action: 'Clic - CTA',
    label: 'Accueil - assistance'
  },
  HOME_SERVICE_CONCIERGE: {
    category: 'Conciergerie',
    action: 'Clic - CTA',
    label: 'Accueil - conciergerie'
  },
  FOOTER_CONTACTS: {
    category: 'Contact gestion',
    action: 'Lien',
    label: 'Footer - contact'
  },
  ONBOARDING_FORGOTTEN_ID: {
    category: 'N° adhérent oublié',
    action: 'Lien',
    label: 'Première connexion - n° adhérent oublié'
  },
  ONBOARDING_TUTO: {
    category: 'Tutoriel connexion',
    action: 'Lien',
    label: 'Première connexion - tutoriel connexion'
  },
  ONBOARDING_CONTINUE: {
    category: 'création de compte',
    action: 'Lien',
    label: 'Première connexion - validation'
  },
  PROFILE_EDIT_ADDRESS: {
    category: 'Modification adresse',
    action: 'Icône',
    label: 'Profil - modification adresse'
  },
  CONTACTS_APPA: {
    category: 'Contact gestion',
    action: 'Lien',
    label: 'Contact - gestion'
  },
  CONTACTS_MERCER: {
    category: 'Contact MERCER',
    action: 'Lien',
    label: 'Contact - MERCER'
  },
  CONTACTS_SANTECLAIR: {
    category: 'Contact SantéClair',
    action: 'Lien',
    label: 'Contact - SantéClair'
  },
  CONTACTS_ASSISTANCE_METRO: {
    category: 'Contact Europ Assistance',
    action: 'Lien',
    label: 'Contact - SantéClair'
  },
  CONTACTS_ASSISTANCE_DROM: {
    category: 'Contact Europ Assistance',
    action: 'Lien',
    label: 'Contact - Europ Assistance DROM'
  },
  CONTRACTS_HEALTH: {
    category: 'Santé',
    action: 'Lien',
    label: 'Contrat - complémentaire santé'
  },
  CONTRACTS_PENSION_HOSPI: {
    category: 'Prévoyance',
    action: 'Lien',
    label: 'Contrat - couverture prévoyance'
  },
  CONTRACTS_ASSISTANCE: {
    category: 'Assistance',
    action: 'Lien',
    label: 'Contrat - assistance'
  },
  CONTRACT_HEALTH_UPDATE_CONTRACT: {
    category: 'Messagerie modification complémentaire santé',
    action: 'Clic - CTA',
    label: 'Complémentaire santé - modification messagerie'
  },
  CONTRACT_HEALTH_REFUNDS: {
    category: 'MERCER',
    action: 'Clic - CTA',
    label: 'Complémentaire santé - remboursement santé'
  },
  CONTRACT_HEALTH_CONTACT_ASSISTANCE: {
    category: 'Complémentaire santé contact assistance',
    action: 'Clic - CTA',
    label: 'Complémentaire santé - contact assistance'
  },
  CONTRACT_HEALTH_ASSISTANCE_METRO: {
    category: 'Complémentaire santé Europ Assistance France',
    action: 'Lien',
    label: 'Complémentaire santé - Numéro Europ Assistance France métropolitaine'
  },
  CONTRACT_HEALTH_ASSISTANCE_DROM: {
    category: 'Complémentaire santé Europ Assistance DROM',
    action: 'Lien',
    label: 'Complémentaire santé - Numéro Europ Assistance DROM'
  },
  CONTRACT_HEALTH_BENEFICIARIES: {
    category: 'MERCER',
    action: 'Clic - CTA',
    label: 'Complémentaire santé - consulter bénéficiaires'
  },
  CONTRACT_HEALTH_UPDATE_BENEFICIARIES: {
    category: 'Messagerie modifier bénéficiaire',
    action: 'Clic - CTA',
    label: 'Complémentaire santé - modifier bénéficiaires messagerie'
  },
  CONTRACT_PENSION_HOSPI_UPDATE_CONTRACT: {
    category: 'Messagerie modification couverture prévoyance',
    action: 'Clic - CTA',
    label: 'Couverture prévoyance - modification messagerie'
  },
  CONTRACT_PENSION_HOSPI_DECLARE: {
    category: 'Messagerie Déclarer sinistre',
    action: 'Clic - CTA',
    label: 'Couverture prévoyance - Déclarer sinistre messagerie'
  },
  CONTRACT_PENSION_HOSPI_UPDATE_PRO_INFO: {
    category: 'Messagerie modifier informations professionnelles',
    action: 'Clic - CTA',
    label: 'Couverture prévoyance - Modifier informations professionnelles messagerie'
  },
  CONTRACT_ASSISTANCE_CONTACT_ASSISTANCE: {
    category: 'Contrat Assistance contact assistance',
    action: 'Clic - CTA',
    label: 'Contrat Assistance - contact assistance'
  },
  CONTRACT_ASSISTANCE_ASSISTANCE_METRO: {
    category: 'Contrat Assistance Europ Assistance France',
    action: 'Lien',
    label: 'Contrat Assistance - Numéro Europ Assistance France métropolitaine'
  },
  CONTRACT_ASSISTANCE_ASSISTANCE_DROM: {
    category: 'Contrat Assistance Europ Assistance DROM',
    action: 'Lien',
    label: 'Contrat Assistance - Numéro Europ Assistance DROM'
  },
  DOCUMENTS_GENERATE_HEALTH_CERTIFICATE: {
    category: 'Attestation santé',
    action: 'Clic - CTA',
    label: 'Document - attestation santé'
  },
  DOCUMENTS_REQUEST_PENSION_CERTIFICATE: {
    category: 'Attestation prévoyance messagerie',
    action: 'Clic - CTA',
    label: 'Document - attestation prévoyance messagerie'
  },
  CONTRIBUTIONS_EDIT: {
    category: 'Modification cotisation',
    action: 'Icône',
    label: 'Cotisation - modification'
  },
  CONTRIBUTIONS_EDIT_GO_MESSAGING: {
    category: 'Messagerie Modifier préférences de paiement',
    action: 'Clic - CTA',
    label: 'Cotisation - modifier préférences paiement messagerie'
  }
};
