import React from 'react';
import { Redirect } from 'react-router-dom';
import { useKeycloakSession, useCms } from '@@hooks';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step5 from './Step5';
import Step4And7 from './Step4And7';
import Step6 from './Step6';
import Step2bis from './Step2bis';
import Layout, { Top } from '@@layout';
import ProgressBar from './components/ProgressBar';

const R = require('ramda');
const RA = require('ramda-adjunct');

const stepLabels = {
  step_1: 'Étape 1 - Identification',
  step_2: 'Étape 2 - Mot de passe',
  step_3: 'Étape 3 - Email',
  step_4: 'Étape 4 - Code de sécurité',
  step_5: 'Étape 5 - Nouveau mot de passe',
  step_6: 'Étape 6 - Numéro de téléphone',
  step_7: 'Étape 7 - Code de sécurité'
};

const stepLabelsForgotPasswordTunnel = {
  step_1: 'Étape 1 - Identification',
  step_2_bis: 'Étape 2 - Email',
  step_4: 'Étape 3 - Code de sécurité',
  step_6: 'Étape 4 - Numéro de téléphone',
  step_7: 'Étape 5 - Code de sécurité',
  step_5: 'Étape 6 - Nouveau mot de passe'
};

const Index = () => {
  const { authenticated } = useKeycloakSession();

  if (authenticated) return <Redirect to="/" />;
  const { contact } = useCms(['contact']);

  const [step, setStep] = React.useState('step_1');
  const [data, setData] = React.useState({});
  const [forgotPasswordTunnel, setForgotPasswordTunnel] = React.useState(false);
  const [skipPhoneValidation, setSkipPhoneValidation] = React.useState(false);

  if (data.onBoarded) return <Redirect to="/" />;

  const modalInstruction = 'Saisir les informations ci-dessous pour recevoir votre code par email ou par téléphone. Tous les champs sont obligatoires.';

  const labelsToUse = forgotPasswordTunnel ? stepLabelsForgotPasswordTunnel : stepLabels;

  const handlePreviousSteps = {
    step2: () => setStep('step_1'),
    step3: () => setStep('step_2'),
    step2bis: () => {
      setStep('step_2');
      setForgotPasswordTunnel(false);
    },
    step5: () => {
      if (forgotPasswordTunnel && !skipPhoneValidation) {
        setStep('step_7');
      }
      if (forgotPasswordTunnel && skipPhoneValidation) {
        setStep('step_6');
      }
      if (!forgotPasswordTunnel && !skipPhoneValidation) {
        setStep('step_4');
      }
    },
    step4And7: (previousStep, otpType) => () => {
      if (forgotPasswordTunnel && R.equals(otpType, 'email')) {
        setStep('step_2_bis');
      } else {
        setStep(previousStep);
      }
    },
    step6: () => setStep('step_4')
  };

  const setStepIfForgotPassword = () => setStep('step_2_bis');

  const skipNumberValidation = () => setStep('step_5');

  const onDone = ({
    id,
    payload
  }) => {
    if (id === 'step_1') {
      setData(R.over(R.lensProp('subscriberId'), R.always(payload.subscriberId)));
      setData(R.over(R.lensProp('onBoarded'), R.always(payload.onBoarded)));
      setStep('step_2');
    } else if (id === 'step_2') {
      setData(R.over(R.lensProp('errorMessage'), R.always(null)));
      setData(R.over(R.lensProp('subscriberId'), R.always(payload.subscriberId)));
      setData(R.over(R.lensProp('email'), R.always(payload.email)));
      setStep('step_3');
    } else if (id === 'step_3') {
      setData(R.over(R.lensProp('anonEmail'), R.always(payload.anonEmail)));
      setData(R.over(R.lensProp('otp'), R.always(payload.otp)));
      setData(R.over(R.lensProp('email'), R.always(payload.email)));
      setStep('step_4');
    } else if (id === 'step_4') {
      forgotPasswordTunnel ? setStep('step_6') : setStep('step_5');
    } else if (id === 'step_5') {
      if (RA.isFalse(forgotPasswordTunnel)) {
        setData(R.over(R.lensProp('phoneNumber'), R.always(payload.phoneNumber)));
        setStep('step_6');
      }
    } else if (id === 'step_6') {
      setData(R.over(R.lensProp('otp'), R.always(payload.otp)));
      setData(R.over(R.lensProp('phoneNumber'), R.always(payload.phoneNumber)));
      setStep('step_7');
    } else if (id === 'step_2_bis') {
      setData(R.over(R.lensProp('anonEmail'), R.always(payload.anonEmail)));
      setData(R.over(R.lensProp('otp'), R.always(payload.otp)));
      setForgotPasswordTunnel(true);
      setStep('step_4');
    }
  };

  const stepToDisplay = R.cond([
    [R.equals('step_1'), R.always(<Step1 id={'step_1'} onDone={onDone}/>)],
    [R.equals('step_2'), R.always(
      <Step2 id={'step_2'}
        subscriberId={data.subscriberId}
        onDone={onDone}
        setData={setData}
        setForgotPasswordTunnel={setForgotPasswordTunnel}
        handlePreviousStep={handlePreviousSteps.step2}
        setStepIfForgotPassword={setStepIfForgotPassword}
      />
    )],
    [R.equals('step_3'), R.always(
      <Step3
        id={'step_3'}
        subscriberId={data.subscriberId}
        onDone={onDone}
        handlePreviousStep={handlePreviousSteps.step3}
        email={data.email}
      />
    )],
    [R.equals('step_4'), R.always(
      <Step4And7
        id={'step_4'}
        otpType={'email'}
        anonEmail={data.anonEmail}
        email={data.email}
        uuid={data.otp}
        onDone={onDone}
        setStep={setStep}
        subscriberId={data.subscriberId}
        forgotPasswordTunnel={forgotPasswordTunnel}
        handlePreviousStep={handlePreviousSteps.step4And7}
      />
    )],
    [R.equals('step_5'), R.always(
      <Step5
        id={'step_5'}
        subscriberId={data.subscriberId}
        onDone={onDone}
        handlePreviousStep={handlePreviousSteps.step5}
        forgotPasswordTunnel={forgotPasswordTunnel}
      />
    )],
    [R.equals('step_6'), R.always(
      <Step6
        id={'step_6'}
        subscriberId={data.subscriberId}
        phoneNumber={data.phoneNumber}
        onDone={onDone}
        modalInstruction={modalInstruction}
        forgotPasswordTunnel={forgotPasswordTunnel}
        handlePreviousStep={handlePreviousSteps.step6}
        setSkipPhoneValidation={setSkipPhoneValidation}
        skipNumberValidation={skipNumberValidation}
      />
    )],
    [R.equals('step_7'), R.always(
      <Step4And7
        id={'step_7'}
        otpType={'phoneNumber'}
        uuid={data.otp}
        onDone={onDone}
        setStep={setStep}
        subscriberId={data.subscriberId}
        phoneNumber={data.phoneNumber}
        forgotPasswordTunnel={forgotPasswordTunnel}
        handlePreviousStep={handlePreviousSteps.step4And7}
      />
    )],
    [R.equals('step_2_bis'), R.always(
      <Step2bis
        id={'step_2_bis'}
        email={data.email}
        subscriberId={data.subscriberId}
        onDone={onDone}
        handlePreviousStep={handlePreviousSteps.step2bis}
      />
    )]
  ]);

  if (R.isNil(contact)) return null;

  const getStepIndex = () => {
    return R.split('_', step)[1];
  };

  const getStepLabel = () => {
    return R.prop(step, labelsToUse);
  };

  const progressBar = <ProgressBar
    label={getStepLabel()}
    step={getStepIndex()}
    maxStep={R.keys(labelsToUse).length}
    forgotPasswordTunnel={forgotPasswordTunnel}
  />;

  const top = <Top content={{ contact }} progressBar={progressBar} />;

  return (
    <Layout top={top} doShowMenu={false} withTunnelBackground={true}>
      <div className='f-base-page-container'>
        <div className='f-page'>
          <div className='f-page-body'>
            <div className='f-connect-progress-bar-container'>
              { progressBar }
            </div>
            { stepToDisplay(step) }
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Index;
